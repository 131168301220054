import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query teamQuery {
      img0: file(relativePath: { eq: "team.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img0cn: file(relativePath: { eq: "team_cn.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
	}
      }
      img0en: file(relativePath: { eq: "team_en.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
	}
      }

    }
  `)

  const intl = useIntl()
  const locale = intl.locale
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }

  return (
    <Layout>
      <SEO title="seo.title.Teams" description="seo.desc.Teams" />
      {locale === "zh-hk" && (
        <Img
          className="rounded mx-auto d-block pb-3"
          fluid={data.img0.childImageSharp.fluid}
        />
      )}
      {locale === "zh-cn" && (
        <Img
          className="rounded mx-auto d-block pb-3"
          fluid={data.img0cn.childImageSharp.fluid}
        />
      )}
      {locale === "en" && (
        <Img
          className="rounded mx-auto d-block pb-3"
          fluid={data.img0en.childImageSharp.fluid}
        />
      )}
    </Layout>
  )
}

export default ThePage
